<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Produse">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Produse</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <!-- Full Table -->
      <base-block v-bind:title="'Numar produse listate: '+productNumber">
        <ProductSearch v-bind:search="search" />
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 70%;">Produs</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 14%;">Cod produs</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Imagine</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 40%;">Denumire produs</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 14%;">Pret fara TVA</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 15%">Cantitate</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 7%;"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="products.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="6" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="product in products" :key="Math.random()+''+product.id">
                <b-td v-if="product.type === 'category'" class="d-sm-none" style="width: 15%;">
                  <div class="text-center bg-primary">
                    <span class="text-white font-weight-bolder">{{ product.value }}</span>
                  </div>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-sm-none" style="width: 15%;">
                  <div><span class="text-dark font-weight-bolder">{{ product.value.node.code }}</span></div>
                  <div><span class="text-primary">{{ product.value.node.name }}</span></div>
                  <b-row>
                    <b-col class="col-6 mt-2">
                      <CartControl index="1" size="sm"  v-bind:value="product.value.node.amountInCart" v-bind:product="product.value.node"></CartControl>
                    </b-col>
                    <b-col class="col-6 mt-2 text-right">
                      <a class="align-items-center justify-content-between btn btn-alt-primary btn-sm" @click="savePosition(product.value.node.sku, getDetailUrl(product.value.node.id))">
                        <i class="fa fa-fw fa-info-circle"></i> detalii
                      </a>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td v-if="product.type === 'category'" class="d-none d-sm-table-cell font-w600 font-size-sm" colspan="6">
                  <div class="text-center bg-primary pt-2 pb-2">
                    <span class="text-white font-weight-bolder">{{ product.value }}</span>
                  </div>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-none d-sm-table-cell">
                  <span class="text-dark font-w600">{{ product.value.node.code }}</span>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-none d-sm-table-cell font-size-sm text-center">
                  <a @click="savePosition(product.value.node.sku, getDetailUrl(product.value.node.id))">
                    <img v-bind:src="getImage(product.value.node.defaultImageThumbnail)" v-bind:alt="product.value.node.description" class="product-list-image" />
                  </a>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-none d-sm-table-cell font-size-sm">
                  <a @click="savePosition(product.value.node.code, getDetailUrl(product.value.node.id))" class="product-list-name-link">
                    <span class="text-primary">{{ product.value.node.description }}</span>
                  </a>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-none d-sm-table-cell text-center">
                  <span class="text-dark font-w600">{{ product.value.node.priceModel.unitPrice.toFixed(2) }} <span class="font-size-sm">lei</span></span>
                </b-td>
                <b-td v-if="product.type === 'product'" class="d-none d-sm-table-cell border-right-0">
                  <CartControl index="2" size="md"  v-bind:value="product.value.node.amountInCart" v-bind:product="product.value.node"></CartControl>
                </b-td>
                <b-td v-if="product.type === 'product'" class="border-left-0 d-none d-sm-table-cell">
                  <ProductPromotion v-bind:promotion-prop="product.value.node.priceModel.promotions"></ProductPromotion>
                </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <!-- END Page Content -->
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <go-top :size="50" :bottom="50" bg-color="#5c80d1"></go-top>
  </div>
</template>

<script>
import ProductSearch from "./partial/ProductSearch";
import ProductPromotion from "./partial/ProductPromotion";
import InfiniteLoading from 'vue-infinite-loading';
import {fleshErrorMessage} from '@/error/server-error';
import {ITEM_NUMBER_PER_PAGE} from "@/constants/settings";
import {ALL_PRODUCT_LIST} from "@/constants/product-graphql";
import CartControl from "../cart/CartControl";
import GoTop from '@inotom/vue-go-top';
import ProductModalDetail from './ProductModalDetail'

export default {
  name: "ProductList",
  components: {ProductSearch,InfiniteLoading,ProductPromotion,CartControl, GoTop},
  data () {
    return {
      products: [],
      search: (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null,
      cursor: null,
      pageInfo: null,
      loaded: false,
      productNumber: 0,
      noImage: require('../../../assets/no-image.png'),
      category: null,
      anchor: null,
      position: 0,
      positionAnchor : 0,
      scrollPosition: 0
    }
  },
  watch: {
    $route() {
      this.products = [];
      this.pageInfo = null;
      this.loaded = false;
      this.search = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$refs.infiniteLoading.attemptLoad();
      this.positionAnchor = 0;
      this.position = 0;
      this.category = null;
      this.scrollPosition = 0;
    }
  },
  methods: {
    loadMore ($state) {
      /*if (this.position === 0) {
        this.handleAnchor();
      }*/
      ++this.position;
      this.$apollo.query({
        query: ALL_PRODUCT_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null,
          keyword: (this.search && this.search.keyword) ? this.search.keyword : null,
          category: (this.search && this.search.category) ? this.search.category : null,
          mainCategory: (this.search && this.search.mainCategory) ? this.search.mainCategory : null,
          property: (this.search && this.search.category && this.search.subControls) ? JSON.stringify(this.search.subControls) : null,
          promotion: (this.search && this.search.onlyPromo) ? 'yes' : null,
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.listProducts.pageInfo;
        let category = this.category;
        let products = this.products;
        result.data.listProducts.edges.forEach(function (item) {
          if (category !== item.node.category.name) {
            category = item.node.category.name;
            products.push({type: 'category', value: category, id: item.node.category._id});
          }
          products.push({type: 'product', value: item, id: item.node._id});
        });
        this.category = category;
        this.products.push(...result.data.listProducts.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.productNumber = result.data.listProducts.totalCount;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDetailUrl(id)
    {
      return id;
    },
    getImage(imageUrl)
    {
      if (!imageUrl) {
        return this.noImage;
      }
      let url = process.env.VUE_APP_IMG_URL;
      return url+imageUrl;
    },
    savePosition(code, url) {
      this.$FModal.show(
        {
          component: ProductModalDetail,
          maxHeight: '100%',
          width: '100%',
          escToClose: false
        },
        {
          productId: url,
          code: code
        }
      )
    },
    handleAnchor() {
      let anchorData = window.location.hash.replace('#', '');
      if (anchorData) {
        let parameters = atob(anchorData).split(',')
        this.anchor = parameters[0];
        this.positionAnchor = parseInt(parameters[1]);
        this.scrollPosition = parseInt(parameters[2]);
      }
    },
    navigateToProduct()
    {
      if (this.anchor) {
        window.scrollTo(0, this.scrollPosition);
        history.replaceState(null, null, window.location.href.replace(window.location.origin, '').split('#')[0]);
      }
      if (this.positionAnchor <= this.position) {
        this.anchor = null;
      }
    }
  },
  mounted() {
    this.navigateToProduct();
  },
  updated() {
    this.$nextTick(function () {
      this.navigateToProduct();
    })
  }
}
</script>