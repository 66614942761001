<template>
    <b-row v-if="promotionList" class="w-100">
      <b-col v-for="(promo, index) in promotionList" v-bind:key="index" v-bind:class="promotionCellSize">
        <div class="item item-link-pop item-circle bg-success text-white-75 text-center product-badge" v-b-popover.hover.nofade.bottom="promotionMessage(promo)" v-bind:title="promotionTitle(index)">
          <i v-bind:class="promotionIcon(index)" class="promo-icon"></i>
        </div>
      </b-col>
    </b-row>
</template>

<script>
export default {
  name: "ProductPromotion",
  props: {
    promotionProp: null
  },
  data () {
    return {
      icons: {pp: 'fa fa-boxes', pi: 'fa fa-hourglass-half', up: 'fa fa-money-bill-alt'},
      title: {pp: 'Oferta speciala pentru cantitate!', pi: 'Oferta valabila doar azi!', up: 'Pret special!'},
      promotions: this.formatPromotions(),
    }
  },
  computed: {
    promotionCellSize () {
      return 'col-'+(12/Object.keys(this.promotions).length);
    },
    promotionList() {
      return this.formatPromotions();
    },
  },
  methods: {
    formatPromotions() {
      if (this.promotions) {
        return this.promotions;
      }
      let promotions = {};
      this.promotionProp.forEach(function (promotion) {
        if (!promotions[promotion.type]) {
          promotions[promotion.type] = [];
        }
        promotions[promotion.type].push(promotion);
      });
      return promotions;
    },
    promotionIcon(type) {
      if (this.icons[type]) {
        return this.icons[type];
      }

      return '';
    },
    promotionMessage(promo) {
      let promoMessage = '';
      promo.forEach(function (item) {
        if (promoMessage.length > 0) {
          promoMessage += "\n";
        }
        promoMessage += item.label;
      });

      return promoMessage;
    },
    promotionTitle(type) {
      if (this.title[type]) {
        return this.title[type];
      }

      return '';
    }
  }
}
</script>

<style scoped>

</style>