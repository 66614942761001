<template>
  <div>
    <b-row class="row-deck">
      <!-- Latest Customers -->
      <b-col md="12">
        <base-block content-class="p-0">
          <div class="font-size-sm push mb-0">
            <div v-if="dataLoad" class="text-center">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </div>
            <b-form v-if="!dataLoad" @submit.stop.prevent="onBaseSearchSubmit">
              <div>
                <b-form-checkbox class="mt-1" id="onlyPromo" name="onlyPromo" v-model="$v.searchForm.onlyPromo.$model" switch stacked @change="promoClick">
                  Arata produse promotionale
                </b-form-checkbox>
              </div>
              <div class="form-group form-row">
                <div class="col-sm-12 col-md-3 mt-3">
                  <b-form-input size="md" class="form-control-alt border" id="keyword" name="keyword" placeholder="Cautare dupa cuvinte cheie" v-model="$v.searchForm.keyword.$model"></b-form-input>
                </div>
                <div  class="col-sm-12 col-md-3 mt-3">
                    <b-form-select size="lg" id="category" name="category" class="form-control-alt border filter-select" v-model="$v.searchForm.category.$model" :options="categories" plain @change="loadSubControls"></b-form-select>
                </div>
                <div class="col-sm-12 col-md-3 mt-3" v-for="(control, index) in categoryFilters" :key="index">
                  <b-form-select v-if="!$v.searchForm.subControls[index].$model" size="lg" v-bind:id="index" v-bind:name="index" class="form-control-alt border filter-select" v-model="$v.searchForm.subControls[index].$model" :options="control" plain @input="handlePropertyFilter($event)"></b-form-select>
                  <b-alert v-if="$v.searchForm.subControls[index].$model" size="md" variant="info" show dismissible class="text-center p-1 filter-alert" @dismissed="handlePropertyFilter(index, 'dismiss')">
                    <p class="mb-0"><span class="text-capitalize">{{formatProperty(index)}}:</span> {{$v.searchForm.subControls[index].$model}}</p>
                  </b-alert>
                </div>
                <b-col v-bind:class="{'col-md-12 text-center': (numberOfFilters % 8 === 0 || numberOfFilters % 4 === 0  || numberOfFilters % 7 === 0 || numberOfFilters % 11 === 0), 'col-md-3': (numberOfFilters % 8 !== 0 && numberOfFilters % 4 !== 0)}" class="mt-3">
                  <b-button v-bind:class="{'animated wobble': searchClickAttention}" class="mb-1 filter-btn" size="md" type="submit" variant="primary">
                    <small><i class="fa fa-fw fa-search mr-1"></i> Cautare</small>
                  </b-button>
                  <router-link v-if="search" class="btn btn-md btn-warning ml-2 filter-btn filter-a" to="/product/list" active-class="" v-on:click.native="onClearFilters">
                    <small><i class="fa fa-fw fa-times-circle mr-1"></i> Reseteaza</small>
                  </router-link>
                </b-col>
              </div>
            </b-form>
          </div>
        </base-block>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {fleshErrorMessage, serverErrors} from "@/error/server-error";
import {CATEGORY_FILTER_LIST, MAIN_CATEGORY_LIST} from "@/constants/product-graphql";

export default {
  name: "ProductSearch",
  mixins: [validationMixin],
  props: {
    search: null
  },
  data () {
    return {
      searchForm: {
        keyword: null,
        onlyPromo: null,
        category: null,
        subControls: null,
      },
      categories: [],
      categoryFilters: {},
      searchClickAttention: null,
      isLoadedFilter: true,
      selectedMainCategory: null,
      dataLoad: true,
      formValidators: {
        searchForm: {
          keyword: {},
          onlyPromo: {},
          category: {},
          subControls: {}
        }
      },
    }
  },
  computed: {
    fieldRules() {
      return this.formValidators;
    },
    numberOfFilters() {
      return Object.keys(this.categoryFilters).length+2;
    },
  },
  validations() {
    return this.fieldRules;
  },
  apollo: {
    productMainCategories: {
      query: MAIN_CATEGORY_LIST,
      result(result) {
        if (!result.data) {
          return;
        }
        this.dataLoad = true;
        let mainCategories = [];
        mainCategories.push({value: null, text: 'Filtreaza dupa categorie'});
        result.data.productMainCategories.forEach(function (item) {
          let tmp = {};
          tmp.label = item.name;
          tmp.options = [];
          item.subCategories.forEach(function (subCategory) {
            tmp.options.push({value: subCategory.id, text: subCategory.name})
          });
          mainCategories.push(tmp);
        });
        this.categories = mainCategories;
        this.searchForm.mainCategory = null;
        this.dataLoad = false;
      },
      error(error) {
        serverErrors(error);
      }
    }
  },
  methods: {
    onClearFilters() {
      this.categoryFilters = {};
      this.selectedMainCategory = null;
      this.searchForm = {
        keyword: null,
        onlyPromo: null,
        category: null,
        subControls: null,
        mainCategory: null,
      };
    },
    onBaseSearchSubmit() {
      this.$v.searchForm.$touch();
      let path = "/product/list";
      if (this.searchForm.keyword || this.searchForm.onlyPromo || this.searchForm.category || this.searchForm.mainCategory) {
        path = "/product/list?search="+btoa(JSON.stringify(this.searchForm));
      }
      this.$router
          .push(path)
          .catch(error => {
            error = {};
            fleshErrorMessage(error, this);
          });
    },
    handlePropertyFilter(e, action) {
      this.dataLoad = true;
      this.isLoadedFilter = false;
      if (action === 'dismiss') {
        this.$v.searchForm.subControls[e].$model = null;
        if (this.searchForm.subControls.laptopuri_compatibile) {
          this.searchForm.subControls.laptopuri_compatibile = null;
        }
      }
      this.loadSubControlObjects(this.searchForm.category, (this.searchForm.subControls) ? JSON.stringify(this.searchForm.subControls) : null);
    },
    loadSubControls(category) {
      this.categoryFilters = {};
      this.searchForm.subControls = null;
      this.isLoadedFilter = false;
      this.dataLoad = true;
      if (!category) {
        this.subControls = null;
        this.dataLoad = false;
        return;
      }
      this.attentionSearchClick();
      this.loadSubControlObjects(category, this.searchForm.subControls ? JSON.stringify(this.searchForm.subControls) : null);
    },
    initValidators() {
      let rules = {
        searchForm: {}
      };
      this.objectAttributeHandler(rules.searchForm, this.searchForm);
      this.formValidators = rules;
    },
    objectAttributeHandler(rules, obj) {
      let parent = this;
      if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach(function(item) {
          rules[item] = {};
          if (typeof obj[item] === 'object' && obj[item] !== null) {
            parent.objectAttributeHandler(rules[item], obj[item]);
          }
        });
      }
    },
    formatProperty(property) {
      return property.replace('_', ' ').toLowerCase()
    },
    loadSubControlObjects(category, filter) {
      this.$apollo.query({
        query: CATEGORY_FILTER_LIST,
        variables: {
          id: category,
          filter: filter,
        }
      }).then((result) => {
        let items = {};
        let control = (this.searchForm.subControls) ? this.searchForm.subControls : {};
        let parent = this;
        result.data.productCategory.filterObjects[0].items.forEach(function(item) {
          if (!items[item.attribute]) {
            items[item.attribute] = [];
            items[item.attribute].push({value: null, text: 'Filtreaza dupa '+parent.formatProperty(item.attribute)})
          }
          if (!control[item.attribute]) {
            control[item.attribute] = null;
          }
          items[item.attribute].push(item.value);
        });
        this.searchForm.subControls = control;
        this.initValidators();
        this.categoryFilters = items;
        this.attentionSearchClick();
        this.dataLoad = false;
      }).catch((error) => {
        fleshErrorMessage(error, this);
        this.dataLoad = false;
      });
    },
    attentionSearchClick()
    {
      if (!this.isLoadedFilter) {
        this.searchClickAttention = 'animated wobble';
        setTimeout(() => this.searchClickAttention = null, 1200);
      }
    },
    promoClick() {
      this.onBaseSearchSubmit();
    }
  },
  mounted() {
    if (this.search) {
      this.isLoadedFilter = true;
      this.searchForm = this.search;
      if (this.searchForm.category) {
        this.loadSubControlObjects(this.searchForm.category, (this.searchForm.subControls) ? JSON.stringify(this.searchForm.subControls) : null)
      }
      this.dataLoad = false;
    } else {
      this.searchForm = {
        keyword: null,
        onlyPromo: null,
        category: null,
        subControls: null,
      };
      this.dataLoad = false;
    }
  }
}
</script>

<style scoped>

</style>